<template>
  <v-container
    id="register"
    style="margin-top: 45px; height: 100vh"
    class="fill-height justify-center pa-0"
    tag="section"
  >
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-img
          style="width: 47%; max-width: 250px"
          class="mx-auto"
          src="../../../../../../assets/dxa-white.png"
        />
      </v-col>
      <v-col cols="12">
        <h1
          class="text-center"
          style="font-weight: bold; color: var(--primary); font-size: 27px"
          v-if="$t(`alpha_register_1_header`) != `alpha_register_1_header`"
        >
          {{ $t(`alpha_register_1_header`) }}
        </h1>
      </v-col>
      <v-col cols="12">
        <h5
          class="text-center mx-5"
          style="font-size: 18px; line-height: 21px"
          v-if="
            $t(`alpha_register_1_subheader`) != `alpha_register_1_subheader`
          "
        >
          {{ $t(`alpha_register_1_subheader`) }}
        </h5>
      </v-col>
    </v-row>
    <v-slide-y-transition appear>
      <v-card
        color="white"
        light
        max-width="100%"
        class="px-5 py-3 mb-0"
        style="margin: 20px auto; display: block; min-height: 400px"
      >
        <div class="pa-2">
          <v-row
            justify="center"
            align="center"
            class="my-3 mx-2"
            v-if="emailSent"
          >
            <h2 class="text-center">{{ $t("alpha_register_email_sent") }}</h2>
          </v-row>
          <v-form ref="form" v-model="valid" :lazy-validation="lazy" v-else>
            <span>{{ $t("account_type") }}</span>
            <v-radio-group
              v-model="user.JuridicalType"
              row
              @change="juridical_type_changed"
            >
              <v-row justify="start" class="mx-1">
                <v-radio :value="0" class="mr-8">
                  <template v-slot:label>
                    <span style="color: var(--dark)">{{
                      $t("fisical_person")
                    }}</span>
                  </template></v-radio
                >
                <v-radio :value="1">
                  <template v-slot:label>
                    <span style="color: var(--dark)">{{
                      $t("legal_person")
                    }}</span>
                  </template></v-radio
                >
              </v-row>
            </v-radio-group>
            <span>{{ $t("name") }}</span>
            <input
              class="register-text-field"
              :class="{ 'invalid-field': !user.Name }"
              type="text"
              v-model="user.Name"
            />

            <div v-if="user.JuridicalType == JuridicalTypeEnum.NaturalPerson">
              <span>{{ $t("last_name") }}</span>
              <input
                class="register-text-field"
                :class="{ 'invalid-field': !user.LastName }"
                type="text"
                v-model="user.LastName"
              />
            </div>

            <span>{{ $t("email") }}</span>
            <input
              class="register-text-field"
              :class="{
                'invalid-field':
                  !user.Email || emailExists || emailFormatInvalid,
              }"
              @change="check_email()"
              type="text"
              v-model="user.Email"
            />
            <p
              style="color: var(--dark); font-size: 11px"
              class="mb-4 mt-n4 ml-2"
              v-if="validatingEmail"
            >
              {{ $t("validating") }}
            </p>
            <p
              style="color: red; font-size: 11px"
              class="mb-4 mt-n4 ml-2"
              v-else-if="emailFormatInvalid"
            >
              {{ $t("format_invalid") }}
            </p>
            <p
              style="color: red; font-size: 11px"
              class="mb-4 mt-n4 ml-2"
              v-else-if="emailExists"
            >
              {{ $t("user_exists") }}
            </p>

            <v-row justify="center" class="mb-4">
              <v-btn
                @click="checkForm"
                color="primary"
                class="mx-0"
                :loading="loading"
                style="width: 90%; height: 52px"
                ><span style="font-size: 1.3rem">{{
                  $t("alpha_register_create_portfolio")
                }}</span></v-btn
              >
            </v-row>
          </v-form>
          <v-alert class="ma-2" v-if="alert" type="error">
            {{ $t(alert) }}
          </v-alert>
        </div>
      </v-card>
    </v-slide-y-transition>
  </v-container>
</template>

<style lang="scss">
.v-application.mt-10 {
  margin-top: 4px !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import PartnerService from "@/services/PartnerService";
import ValidationServices from "../../../../../../services/ValidationService";
import { JuridicalTypeEnum } from "@/shared/enums/JuridicalType";

export default {
  name: "AlphaRegister",
  components: {},
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    vs: new ValidationServices(),
    ps: new PartnerService(),
    loading: false,
    valid: true,
    validatingEmail: false,
    emailFormatInvalid: false,
    emailExists: false,
    lazy: false,
    alert: null,
    emailSent: false,
    user: {
      LastName: "",
      Email: "",
      SocialNumber: "",
      PartnerId: null,
      JuridicalType: 0,
    },
    JuridicalTypeEnum,
  }),
  async created() {
    var partnerUrl = this.$route.query.partner;
    var bankerId = this.$route.query.banker;
    this.user.PartnerId = await this.ps.validate_partner(partnerUrl);
    if (bankerId) {
      if (!(await this.gs.validate_banker(bankerId))) {
        this.$router.replace({
          query: { partner: partnerUrl, banker: undefined },
        });
      } else {
        this.user.BankerId = bankerId;
      }
    }
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    email_valid() {
      return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        this.user.Email
      );
    },
    accent_valid() {
      return !/[À-ÖØ-öø-ÿ]/.test(this.user.Email);
    },
  },
  methods: {
    juridical_type_changed() {
      this.user.LastName = null;
    },
    async check_email() {
      this.emailFormatInvalid = false;
      if (!this.email_valid || !this.accent_valid) {
        this.emailFormatInvalid = true;
        return;
      }
      this.validatingEmail = true;
      this.emailExists = await this.vs.check_if_email_exists(
        null,
        this.user.Email,
        true
      );
      this.validatingEmail = false;
    },
    checkForm: function () {
      this.alert = null;
      if (this.loading || this.validatingEmail) {
        return;
      }
      var result = false;
      if (
        this.user.Email &&
        this.user.Name &&
        (this.user.JuridicalType == this.JuridicalTypeEnum.LegalPerson ||
          this.user.LastName) &&
        !this.emailExists &&
        !this.emailFormatInvalid
      ) {
        result = true;
      } else {
        if (!this.emailExists && !this.emailFormatInvalid) {
          this.alert = this.$t("all_fields_required");
        }
        return;
      }
      this.loading = true;
      if (result) {
        this.register();
      } else {
        this.loading = false;
      }
    },
  },
};
</script>
<style>
.crisp-client {
  display: none;
}
</style>
<style lang="sass">
#register
  .v-list-item__subtitle
    -webkic-line-clamp: initial
    -webkit-box-orient: initial
</style>
